<template>
  <b-card-code title="This is a general setting for project">
    <div>
      <b-form class="position-relative" @submit.prevent="onSubmit">
        <b-form-group
          label="Project UUID"
          label-for="form-name"
          label-cols-lg="2"
        >
          <div v-if="projectData && projectData.uuid">
            {{ projectData.uuid }}
          </div>
        </b-form-group>

        <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
          <template #overlay>
            <div
              v-if="processing"
              class="text-center p-3 bg-primary text-light rounded"
            >
              <feather-icon icon="UploadCloudIcon" size="20" />
              <div class="mb-2">Preservation...</div>
              <b-progress
                min="1"
                max="5"
                :value="counter"
                variant="success"
                height="6px"
                class="mx-n3"
              />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <b-card-text class="font-weight-bolder">
                Are you sure?
              </b-card-text>
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="onOK"
                >
                  OK
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </b-form>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BOverlay,
  BForm,
  BProgress,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BCardCode,
    BFormInput,
    BCardText,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  computed: {
    projectInfo() {
      return this.$store.state.Project.project;
    },
    projectSetting() {
      const setting = this.$store.state.Editor.cameraSetting;
      if (setting.project_setting == undefined) {
        return { frequency: 0, vr: false, ar: false };
      }
      return setting.project_setting;
    },
  },
  data() {
    return {
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      fullScreenButton: false,
    };
  },
  beforeDestroy() {
    this.clearInterval();
  },
  setup() {
    const projectData = ref(null);

    store
      .dispatch("Project/loadProject", store.state.Project.project.id)
      .then((response) => {
        projectData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          projectData.value = undefined;
        }
      });

    return {
      projectData,
    };
  },
  methods: {
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus();
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus();
    },
    onSubmit() {
      this.processing = false;
      this.busy = true;
      console.log("////", this.$route.params.projectId);
    },
    onCancel() {
      this.busy = false;
    },
    onOK() {
      this.counter = 1;
      this.processing = true;
      // Simulate an async request
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 5) {
          this.counter += 1;
        } else {
          this.clearInterval();
          const setting = this.$store.state.Editor.cameraSetting;
          setting.project_setting = this.frequencyRate;
          this.$store.commit("Editor/SET_CAMERA", setting);

          this.$nextTick(() => {
            // eslint-disable-next-line
            this.busy = this.processing = false;
            this.$store.dispatch(
              "Editor/changeSetting",
              this.$route.params.projectId
            );
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "You have successfully update general setting",
                icon: "EditIcon",
                variant: "success",
              },
            });
          });
        }
      }, 350);
    },
  },
  mounted() {
    const setting = this.$store.state.Editor.cameraSetting;
    if (setting.project_setting == undefined) {
      this.frequencyRate = { frequency: 0, vr: false, ar: false };
    } else {
      this.frequencyRate = setting.project_setting;
    }
  },
};
</script>

<style lang="scss">
.code-toggler {
  display: none;
}

#checkbox {
  margin: 30px 0;
  .form-row {
    legend {
      padding: 0 0 0 5px !important;
    }
  }
  label {
    cursor: pointer;
  }
}
</style>
